import PropTypes from 'prop-types';
import React from 'react';

import { IntlProvider } from 'react-intl';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import products from './products';

const ErySettingsContext = React.createContext({
  product: 'NAery',
  productPhonemes: ['N', 'A'],
});

const useErySettings = () => {
  const erySettings = React.useContext(ErySettingsContext);

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useDebugValue(erySettings);
  }

  return erySettings;
};

const ErySettingsProvider = ({ settings, children }) => {
  const product = settings.product && products[settings.product];
  const productTheme = (product && product.theme) || {};
  const productPhonemes = (product && product.phonemes) || 'NA';
  const theme = createMuiTheme({ ...productTheme, ...settings.theme });

  return (
    <ErySettingsContext.Provider value={{ productPhonemes, theme }}>
      <IntlProvider locale='en'>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </IntlProvider>
    </ErySettingsContext.Provider>
  );
};

ErySettingsProvider.propTypes = {
  settings: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export { useErySettings, ErySettingsProvider };
