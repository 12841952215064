import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'gatsby-theme-material-ui';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import EryLogo from '../EryLogo';
import EryButtons from '../EryButtons';

import LoginButton from './LoginButton';
import SignupButton from './SignupButton';

const Header = ({ loginButton = true, signupButton = true }) => (
  <Box mb={3}>
    <Grid container justify='space-between' alignItems='center' alignContent='flex-end'>
      <Grid item>
        <Link activeStyle={{ textDecoration: 'none' }} to='/'>
          <EryLogo />
        </Link>
      </Grid>
      <Grid item>
        <EryButtons>
          {signupButton && <SignupButton />}
          {loginButton && <LoginButton />}
        </EryButtons>
      </Grid>
    </Grid>
  </Box>
);

Header.propTypes = {
  loginButton: PropTypes.bool,
  signupButton: PropTypes.bool,
};

export default Header;
