import React from 'react';

import EryButton from '../EryButton';

const ErySubmitButton = (props: Object) => {
  return (
    <EryButton
      disabled={props.disabled || props.isSubmitting || (props.disableOnInvalid && !props.isValid)}
      label={props.label || 'Submit'}
      tabIndex={props.tabIndex}
      type='submit'
    >
      {props.label || props.children}
    </EryButton>
  );
};

export default ErySubmitButton;

export const TestErySubmitButton = ErySubmitButton;
