import PropTypes from 'prop-types';
import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const Section = ({ title, children, ...props }) => (
  <>
    <Box mb='1em'>
      <Typography variant='h3' {...props}>
        {title.toLowerCase()}
      </Typography>
    </Box>
    {children}
  </>
);

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Section;
