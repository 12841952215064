import React from 'react';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import EryFormField from '../EryFormField';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: props => props.alignItems || 'center',
    justifyContent: props => props.justifyContent || 'flex-start',
    flexDirection: props =>
      props.eryWidth && props.eryWidth < (props.eryThresholdWidth || 1200) ? 'column' : props.direction || 'row',
    background: props => props.background || 'none',
  },
  formField: {
    margin: props => `0 ${(props.spacing === null || props.spacing === undefined ? 24 : props.spacing) / 2}px`,
    marginLeft: props => props.eryWidth && props.eryWidth < (props.eryThresholdWidth || 1200) && 'unset !important',
  },
  formFieldEnd: {},
  formFieldStart: {},
});

const EryFormFieldGroup = (props: Object) => {
  const classes = useStyles(props);

  const childrenWithProps = React.Children.map(props.children, child =>
    child.type === EryFormField
      ? React.cloneElement(child, {
          eryWidth: props.eryWidth,
          eryHeight: props.eryHeight,
          formik: props.formik,
          ...props,
          ...child.props,
          children: child.props.children || null,
        })
      : child
  );

  return (
    <div className={classes.root}>
      {childrenWithProps.map((child, i) => (
        <div
          key={i}
          className={classNames(
            classes.formField,
            i === 0 &&
              (!props.eryWidth || (props.eryWidth && props.eryWidth >= (props.eryThresholdWidth || 1200))) &&
              classes.formFieldStart,
            i === props.children.length - 1 &&
              (!props.eryWidth || (props.eryWidth && props.eryWidth >= (props.eryThresholdWidth || 1200))) &&
              classes.formFieldEnd
          )}
        >
          {child}
        </div>
      ))}
    </div>
  );
};

export default EryFormFieldGroup;

export const TestEryFormFieldGroup = EryFormFieldGroup;
