import React, { useState } from 'react';

import EryButton from '../EryButton';

import LoginDialog from './LoginDialog';

const LoginButton = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <EryButton onClick={handleClickOpen}>Login</EryButton>
      <LoginDialog onClose={handleClose} open={open} />
    </React.Fragment>
  );
};

export default LoginButton;
