import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles({
  root: {
    width: props => props.containerWidth || '100%',
    margin: props => props.margin || 0,
  },
  helperText: {
    color: props => (props.error ? '#f44336' : 'initial'),
  },
  input: {
    height: props => props.height || 36,
    width: props => props.width || '100%',
    borderRadius: '2px',
    boxShadow: 'inset 0 1px 1px 0 rgba(188, 173, 173, 0.5)',
    background: props => props.background || '#f6f6f6',
    color: props => props.color || 'black',
    outline: 'none',
    border: 'none',
    opacity: props => (props.disabled ? 0.5 : 1),
    padding: '0 0.5rem',
    fontSize: props => props.fontSize || '14px',
    textAlign: props => props.textAlign || 'left',
  },
});

const EryInsetInput = (props: Object) => {
  const classes = useStyles(props);

  const handleChange = (e: Object) => {
    if (props.onChange) {
      props.onChange(e);
    }

    // This is for triggering onChange event which is not affected by formik.
    // When EryInsetInput is used as formik input and we can't get the onChange event additionally because the event name is the same "onChange".
    // In order to resolve this, onChangeValue is used
    if (props.onChangeValue) {
      props.onChangeValue(e);
    }
  };

  const handleKeyPress = (e: Object) => {
    if (e.key === 'Escape' && props.onPressEscapeKey) {
      props.onPressEscapeKey();
    }

    if (e.key === 'Enter' && e.ctrlKey && props.onPressCtrlEnterKey) {
      props.onPressCtrlEnterKey();
    }
  };

  return (
    <div className={classes.root}>
      <input
        name={props.name}
        className={classes.input}
        disabled={props.disabled}
        value={props.value || ''}
        type={props.type}
        placeholder={props.placeholder}
        onChange={handleChange}
        onBlur={props.onBlur}
        onKeyDown={handleKeyPress}
        tabIndex={props.tabIndex}
        ref={props.refObj}
        autoFocus={props.autoFocus}
      />
      {props.helperText && <FormHelperText className={classes.helperText}>{props.helperText}</FormHelperText>}
    </div>
  );
};

export default EryInsetInput;

export const TestEryInsetInput = EryInsetInput;
