import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  progress: {
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    margin: 'auto',
  },
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    background: '#ffffff88',
  },
}));

const EryLoadingSpinner = (props: Object) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.progress} size={props.size || 40} />
    </div>
  );
};

export default EryLoadingSpinner;

export const TestEryLoadingSpinner = EryLoadingSpinner;
