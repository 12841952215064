import PropTypes from 'prop-types';
import React from 'react';

import Typography from '@material-ui/core/Typography';

const Paragraph = ({ children, ...props }) => (
  <Typography paragraph={true} variant='body2' {...props}>
    {children}
  </Typography>
);

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Paragraph;
