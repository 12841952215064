import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  description: {
    marginBottom: props => props.descriptionSpacing || '3rem',
  },
  dialogContent: {
    marginTop: props => props.contentSpacing || '2rem',
    padding: props => `${props.contentPadding} !important` || '8px 24px',
    overflow: props => props.overflow || 'auto',
  },
  dialogTitle: {
    color: props => props.titleColor || theme.palette.primary.main,
    fontSize: 22,
  },
  modalBackdrop: {
    background: props => props.backdropBackground || 'transparent',
  },
  paperWidthSm: {
    width: props => props.width,
    maxWidth: 'initial',
    margin: '0',
  },
}));

const EryDialog = (props: Object) => {
  const classes = useStyles(props);
  const theme = useTheme();

  return (
    <Dialog
      BackdropProps={{
        className: classes.modalBackdrop,
      }}
      classes={{
        paper: classes.paper,
        root: classes.paper,
      }}
      fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
      onClose={!props.maximize ? props.onClose : null}
      open={props.maximize || props.open}
    >
      {props.isTitleVisible !== false && (
        <DialogTitle disableTypography>
          <Typography className={classes.dialogTitle}>{props.title}</Typography>
          {props.onClose ? (
            <IconButton aria-label='close' className={classes.closeButton} onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>
        {props.description && <DialogContentText className={classes.description}>{props.description}</DialogContentText>}
        {props.children}
      </DialogContent>
      <DialogActions>{props.actions}</DialogActions>
    </Dialog>
  );
};

export default EryDialog;

export const TestEryDialog = EryDialog;
