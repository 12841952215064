const defaultTheme = {
  shape: {
    borderRadius: 0,
  },
  typography: {
    h1: {
      fontFamily: 'Philosopher, Arial',
    },
    h2: {
      fontFamily: 'Philosopher, Arial',
    },
    h3: {
      fontFamily: 'Philosopher, Arial',
    },
    h4: {
      fontFamily: 'Philosopher, Arial',
    },
    body1: {
      fontFamily: 'Roboto, Arial',
    },
    body2: {
      fontFamily: 'Crimsom Text, Times New Roman',
    },
  },
};

const products = {
  behavery: {
    phonemes: ['be', 'hav'],
    theme: {
      ...defaultTheme,
      palette: {
        primary: {
          main: '#0d432a;',
          light: '#92bc33',
        },
        secondary: {
          main: '#0c4225',
          light: '#0c422559',
        },
        product: {
          main: '#92bc33',
          light: '#92bc33aa',
        },
        text: {
          primary: '#4a412a',
          secondary: '#c7c3ba',
          tertiary: '#0d432a;',
          product: '#92bc33',
        },
        lightText: {
          main: '#fffaee',
        },
      },
    },
  },
  stintery: {
    phonemes: ['stint'],
    theme: {
      ...defaultTheme,
      palette: {
        primary: {
          dark: '#aa7500',
          main: '#ffa500',
          light: '#fffaee',
        },
        secondary: {
          main: '#0c4225',
          light: '#0c422559',
        },
        product: {
          main: '#92bc33',
          light: '#92bc33aa',
        },
        text: {
          primary: '#4a412a',
          secondary: '#c7c3ba',
          product: '#ffa500',
        },
        lightText: {
          main: '#fffaee',
        },
      },
    },
  },
};

export default products;
