import PropTypes from 'prop-types';
import React from 'react';

import { Container, Grid } from '@material-ui/core';

import Banner from './Banner';
import Footer from './Footer';
import Header from './Header';
import LoginDialog from './LoginDialog';
import Paragraph from './Paragraph';
import Section from './Section';
import SignupDialog from './SignupDialog';

const Auth0Context = React.createContext();

const Layout = ({ auth0, children, header }) => (
  <Auth0Context.Provider value={auth0}>
    <Container maxWidth='lg'>
      <Grid container direction='column'>
        {header || <Header />}
        <Grid item>{children}</Grid>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </Container>
  </Auth0Context.Provider>
);

Layout.propTypes = {
  auth0: PropTypes.objectOf(PropTypes.func),
  children: PropTypes.node.isRequired,
  header: PropTypes.object,
};

export { Auth0Context, Banner, Footer, Header, LoginDialog, Paragraph, Section, SignupDialog };
export default Layout;
