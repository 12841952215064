import React from 'react';
import Layout from 'ery-components/src/lib/EryLandingLayout';

import auth0 from '../utils/auth0';

import withRoot from '../withRoot';

const NotFoundPage = () => (
  <Layout auth0={auth0}>
    <h1>404: NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default withRoot(NotFoundPage);
