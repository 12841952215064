import React from 'react';

import Layout, { Header, LoginDialog } from 'ery-components/src/lib/EryLandingLayout';

import withRoot from '../withRoot';
import auth0 from '../utils/auth0';

const LoginPage = () => (
  <Layout auth0={auth0} header={<Header loginButton={false} signupButton={false} />}>
    <LoginDialog maximize />
  </Layout>
);

export default withRoot(LoginPage);
